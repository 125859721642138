import { render, staticRenderFns } from "./dict-detail.vue?vue&type=template&id=30c4c2cc&scoped=true&"
import script from "./dict-detail.vue?vue&type=script&lang=ts&"
export * from "./dict-detail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c4c2cc",
  null
  
)

export default component.exports