





















































import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Todo extends Vue {
  modelFieldList: any = [
    { c1: '01', c2: '0', c3: '业务类型', c4: '', c5: 0 },
    { c1: '02', c2: '1', c3: '商户名称', c4: '', c5: 0 },
    { c1: '03', c2: '2', c3: '合同名称', c4: '', c5: 0 },
    { c1: '04', c2: '3', c3: '项目名称', c4: '', c5: 0 },
    { c1: '05', c2: '4', c3: '数据日期', c4: '', c5: 0 }
  ];
}
